import {Button, Input, message} from "antd";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import AuthLayout from "../../layout/auth";
import Style from "../../assets/css/panel.module.css";
import Logo from "../../assets/img/logo-alt.svg";
import {changePasswordWithPinApi, getPinForForgotPasswordApi} from "../../api/api";
import {useTranslation} from "react-i18next";
import Language from "../../layout/components/common/language";
import {useSelector} from "react-redux";

const Forget = () => {

    const { t } = useTranslation();
    const translate = t;
    const navigate = useNavigate();
    const [buttonLoading, setButtonLoading] = useState(false);
    const [forgetData, setForgetData] = useState({});
    const [forgetPassData, setForgetPassData] = useState({});
    const [showPin, setShowPin] = useState(false);
    const dir = useSelector((state) => state.auth?.setting?.dir || "ltr");

    useEffect(() => {
        document.title = `${translate('wingo')} - ${translate('forget_password')}`;
    }, []);

    const getPinForForgotPassword = async () => {
        setButtonLoading(true)
        await getPinForForgotPasswordApi(forgetData).then(()=>{
            setButtonLoading(false)
            setShowPin(true)
            message.success(translate('error_2fa_sent'))
        }).catch((error)=>{
            const errorMessage = error.response.data.message;
            setButtonLoading(false)
            message.error(errorMessage)
        })
    }

    const changePasswordWithPin = async () => {
        setButtonLoading(true)
        const data = {
            email: forgetData.email,
            pin: forgetPassData.pin,
            password: forgetPassData.first
        }
        await changePasswordWithPinApi(data).then(()=>{
            setButtonLoading(false)
            setShowPin(true)
            message.success(translate('error_password'))
            navigate("/login")
        }).catch((error)=>{
            const errorMessage = error.response.data.detail;
            setButtonLoading(false)
            message.error(errorMessage)
        })
    }


    return (
        <AuthLayout>
            <div className="mh-100 d-md-flex">
                <div className={Style.AuthSider40}>
                    <div className={Style.AuthRegSplash}>
                        <div>
                            <img src={Logo} width={Logo.width} height={Logo.height}/>
                        </div>
                    </div>
                </div>
                <div className={Style.AuthSider60}>
                    <div className={Style.AuthForm}>
                        <div className={Style.AuthLogin}>
                            <div>
                                <div className="d-flex align-items-center justify-content-between">
                                    <img width={211} height={73} src={Logo}/>
                                    <Language style={{marginRight: "0px"}}/>
                                </div>
                                <h6>{translate('forget_password')}</h6>
                            </div>
                            {showPin ?
                                <div key={1}>
                                    <div>
                                        <Input
                                            autoComplete="new-password"
                                            placeholder={translate('enter_pin')}
                                            name="pin"
                                            style={{borderRadius: '25px'}}
                                            onChange={(e)=>setForgetPassData({...forgetPassData, pin: e.target.value})}
                                        />
                                    </div>
                                    <div style={{marginTop: "15px"}}>
                                        <Input.Password
                                            autoComplete="new-password"
                                            placeholder={translate('enter_new_password')}
                                            style={{borderRadius: '25px'}}
                                            type="password"
                                            onChange={(e)=>setForgetPassData({...forgetPassData, first: e.target.value})}
                                        />
                                    </div>
                                    <div style={{marginTop: "15px"}}>
                                        <Input.Password
                                            autoComplete="new-password"
                                            placeholder={translate('repeat_new_password')}
                                            style={{borderRadius: '25px'}}
                                            type="password"
                                            onChange={(e)=>setForgetPassData({...forgetPassData, second: e.target.value})}
                                        />
                                    </div>
                                    <Button
                                        style={{marginTop: "35px"}}
                                        shape="round"
                                        size="large"
                                        className="dark-green-button white-color"
                                        loading={buttonLoading}
                                        onClick={()=>changePasswordWithPin()}
                                        disabled={!forgetPassData?.pin || !forgetPassData?.first || !forgetPassData?.second || (forgetPassData?.first !== forgetPassData?.second)}
                                    >
                                        {translate('confirm')}
                                    </Button>
                                    <Button size="large" style={dir === "ltr" ? {marginLeft: "10px"} : {marginRight: "10px"}} shape="round" onClick={()=> setShowPin(false)}>{translate('back')}</Button>
                                </div>
                                :
                                <div key={2}>
                                    <div>
                                        <Input
                                            placeholder={translate('profile_email')}
                                            name="forget"
                                            style={{borderRadius: '25px'}}
                                            onChange={(e)=> setForgetData({...forgetData, email: e.target.value})}
                                        />
                                    </div>
                                    <Button
                                        style={{marginTop: "35px"}}
                                        shape="round"
                                        size="large"
                                        className="dark-green-button white-color"
                                        loading={buttonLoading}
                                        onClick={()=>getPinForForgotPassword()}
                                        disabled={!forgetData?.email}
                                    >
                                        {translate('send_pin')}
                                    </Button>
                                </div>
                            }
                        </div>
                        <div className={Style.AuthNotRegister}>
                            <h6>{translate('remember_password')}</h6>
                            <span>{translate('back_to_login')}</span>
                            <Button size="large" onClick={()=> navigate("/login")} className="dark-green-button white-color" shape="round" style={{marginTop: "25px", display: "flex"}}>
                                {translate('login')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </AuthLayout>
    )
}

export default Forget;